import React from "react";
import "./index.css";
import { useDispatch } from "react-redux";
import { setMultiplyViewActiveStageIsActive, setMultiplyViewStageIsActive } from "../../../store/reducers/extensionsReducer";
import DraggableSectionWrapper from "./DraggableSectionWrapper";
import DragAndDrop, { DRAG_N_DROP_MOVE_TYPES } from "./drag-n-drop";
import { EXTENSIONS } from "../../extensions";
import _ from 'lodash';

const DragAndDropTemplate = (props) => {
  const {
    schema_key,
    drag,
    schema_id,
    cardHeight,
    cardWidth,
    sectionWrapper,
    elementContent,
    data,
    cardStyle,
    list,
    extensions,
    action,
    onListEnd,
    loading,
    multiSelect,
  } = props;

  const createStoreAction = useDispatch();

  const setObjectFinishStage = (info) => {
    createStoreAction(
      setMultiplyViewActiveStageIsActive({
        type: info.to_section.key,
        value: true,
      })
    );
  };

  const onMouseDownCallBack = () => {
    setStageElementsActive(true);
  };

  // CONDITIONS START
  const stageExtensionCondition = (event) =>
    event.isDragActive && event.info.to_section && event.info.to_section.type === DRAG_N_DROP_MOVE_TYPES.FINAL_STAGE;
  const moveCondition = (event) => event.isDragActive && event.info.to_section && event.info.to_section.type === DRAG_N_DROP_MOVE_TYPES.MOVE;
  const clickCondition = (event) => !event.isDragActive;
  // CONDITIONS END

  const onMouseUpCallBack = (event) => {
    if (clickCondition(event)) {
      setStageElementsActive(false);
      navigateToObject(event.info.drag_item_id);
    } else if (moveCondition(event)) {
      setStageElementsActive(false);
      action && action(event.info, BoardDND.reverseDragAction);
    } else if (stageExtensionCondition(event)) {
      setObjectFinishStage(event.info);
    }
  };

  const navigateToObject = (id) => {
    // navigate(`/${schema_key}/${id}`);
    // console.log(`/${schema_key}/${id}`);
    // console.log(window.location);
    const url = window.location.origin + `/${schema_key}/${id}`;
    window.open(url, "_blank").focus();
  };

  const setStageElementsActive = (value) => {
    createStoreAction(setMultiplyViewStageIsActive(value));
  };

  const BoardDND = new DragAndDrop(cardStyle, onMouseUpCallBack);

  const onMouseDown = (event) => {
    if (drag) {
      BoardDND.onMouseDown(event, onMouseDownCallBack);
    } else {
      return null;
    }
  };

  return (
    <>
      {!!data &&
        _.isArray(data) &&
        data.map((section, key) => (
          <DraggableSectionWrapper
            {...{
              drag,
              multiSelect,
              navigateToObject,
              onMouseDown,
              cardHeight,
              cardWidth,
              list,
              cardStyle,
              elementContent,
              sectionWrapper,
              onListEnd,
              loading,
              section,
              key,
            }}
          />
        ))}
      {extensions && EXTENSIONS.STAGE.MULTIPLE_VIEW.component({ dragNDrop: BoardDND })}
    </>
  );
};

DragAndDropTemplate.defaultProps = {
  extensions: true,
  drag: true,
  multiSelect: false,
};

export default DragAndDropTemplate;
