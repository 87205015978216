import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./index.module.css";
import Spinner from "../../../kit/Loaders/Spinner";
import { useIntersectionObserver } from "usehooks-ts";
import { isValue } from "../../../../helpers/values-validator";
import Page404 from "../../../../pages/error/Page404";
import _ from "lodash";

const UngroupedViewRowList = (props) => {

	const {
		data, isPaginationLoading, onListEnd, element, elementWrapperStyle, onElementClick, style
	} = props;

	const ref = React.useRef(null);

	const entry = useIntersectionObserver(ref, {});
	const isVisible = !!entry?.isIntersecting;

	useEffect(() => {
		if (isVisible && onListEnd && !isPaginationLoading) {
			onListEnd();
		}
	}, [isVisible, ref]);

	return (
		<div {...{className: cn(styles.ungroupedViewRowList)}}>
			<div {...{className: cn(styles.ungroupedViewRowElementsSection), style}}>
				{
					isValue(data) ?
					data.map(item => {
						return (
							<div {...{
								className: cn(styles.ungroupedViewRowElementsSectionItem),
								onClick: () => onElementClick(item),
								style: elementWrapperStyle,
								key: _.uniqueId()
							}}>
								{element(item)}
							</div>
						);
					}) : <div {...{className: cn(styles.ungroupedViewRowEmptyPage)}}><Page404/></div>
				}
			</div>
			<div {...{
				ref,
				className: cn("ListTemplate_end-list", isPaginationLoading && "margin-top-14 ListTemplate_end-list_loading")
			}}>
				<Spinner {...{
					loading: isPaginationLoading,
					color: 'var(--gray1)',
					size: 30
				}}/>
			</div>
		</div>
	);
};

export default UngroupedViewRowList;
