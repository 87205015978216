// @ts-nocheck
import { ApiEndpoint } from "../api-service";
import { api } from "../index.jsx";
import * as core from "../entry-points.jsx";
import {
  AddSchemaField,
  CreateChoice,
  CreateSchema,
  DeleteChoice,
  DeleteSchemaField,
  GetSchemas,
  UpdateChoice,
  UpdateSchema,
  UpdateSchemaField,
} from "../interfaces/schemas";
import { CreateGroup, EditGroup, GetSingleView, GetViews, MoveField, RemoveGroup, UpdateView } from "../interfaces/views";
import {
  CREATE_SCHEMA_TEMP,
  GET_OBJECT_LOGS_TEMP,
  GET_RELATIONS_SCHEMA,
  GET_SCHEMAS_BY_CAMPAIGN,
  UPDATE_OBJECTS_OWNERS_FULLY,
} from "../entry-points.jsx";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

// - objects
const getGroupedObjects = (payload) => loginEndpoint.request(core.GET_GROUPED_OBJECTS(payload.query), payload.data, "post");
const getGroupedObjectsTest = (payload, signal) =>
  loginEndpoint.request(core.GET_GROUPED_OBJECTS_TEST(payload.query), payload.data, "post", {}, signal);

const getUnGroupedObjects = (payload) => loginEndpoint.request(core.GET_UNGROUPED_OBJECTS(payload.query), payload.data, "post");
const updateObject = (payload) => loginEndpoint.request(core.UPDATE_OBJECT(payload.query), { data: payload.data }, "put");
const createObject = (payload) => loginEndpoint.request(core.CREATE_OBJECT(payload.query), payload.data, "post");
const getSingleObject = (payload) => loginEndpoint.request(core.GET_OBJECT(payload.query), null, "get");
const addObjectOwner = (payload) => loginEndpoint.request(core.ADD_OBJECT_OWNER, payload.data, "post");
const deleteObjectOwner = (payload) => loginEndpoint.request(core.DELETE_OBJECT_OWNER, payload.data, "delete");
const addObjectNewRelationObject = (payload) =>
  loginEndpoint.request(core.ADD_NESTED_OBJECT(payload.query.object_id, payload.query.params), null, "post");
const getObjectFilters = (payload) => loginEndpoint.request(core.GET_OBJECT_FILTERS(payload.query), null, "get");
const tempObjectCreate = (payload) => loginEndpoint.request(core.TEMP_OBJECT_CREATE(payload.query), null, "post");
const moveObjectBetweenSchemas = (payload) => loginEndpoint.request(core.MOVE_OBJECTS_BETWEEN_SCHEMAS(payload.query), payload.data, "post");
const updateObjectsOwnersFully = (payload) => loginEndpoint.request(core.UPDATE_OBJECTS_OWNERS_FULLY(payload.query), payload.data, "patch");

// - views
const getMultipleView = (payload) => loginEndpoint.request(core.GET_MULTIPLE_VIEW(payload.query), null, "get");
const getViews: GetViews = (payload) => loginEndpoint.request(core.GET_VIEWS(payload.query), null, "get");
const getSchemaElementView = (payload) => loginEndpoint.request(core.GET_SCHEMA_ELEMENT_VIEW(payload.query), null, "get");
const getViewsElements = (payload) => loginEndpoint.request(core.GET_VIEWS_ELEMENTS(payload.query), null, "get");
const updateView: UpdateView = (payload) => loginEndpoint.request(core.UPDATE_VIEW, payload, "put");
const getSingleView: GetSingleView = (payload) => loginEndpoint.request(core.GET_SINGLE_VIEW(payload.query), null, "get");
const moveField: MoveField = (payload) => loginEndpoint.request(core.MOVE_FIELD, payload, "patch");
const editGroup: EditGroup = (payload) => loginEndpoint.request(core.EDIT_GROUP, payload, "patch");
const removeGroup: RemoveGroup = (group_id) => loginEndpoint.request(core.REMOVE_GROUP(group_id), null, "delete");
const createGroup: CreateGroup = (payload) => loginEndpoint.request(core.CREATE_GROUP, payload, "post");

// - schemas

const getSchemaFields = (payload) => loginEndpoint.request(core.GET_SCHEMA_FIELDS(payload.query), null, "get");
const getRelationsSchema = (payload) => loginEndpoint.request(core.GET_RELATIONS_SCHEMA(payload.query.schema_id), null, "get");
const getSchemas: GetSchemas = () => loginEndpoint.request(core.GET_SCHEMAS, null, "get");
const addSchemaField: AddSchemaField = (schema_id, payload) => loginEndpoint.request(core.ADD_SCHEMA_FIELD(schema_id), payload, "post");
const deleteSchemaField: DeleteSchemaField = (field_id) => loginEndpoint.request(core.DELETE_SCHEMA_FIELD(field_id), null, "delete");
const updateSchema: UpdateSchema = (field_id, payload) => loginEndpoint.request(core.UPDATE_SCHEMA(field_id), payload, "patch");

const updateSchemaField: UpdateSchemaField = (field_id, payload) => loginEndpoint.request(core.UPDATE_SCHEMA_FIELD(field_id), payload, "patch");
const updateChoice: UpdateChoice = (choice_id, payload) => loginEndpoint.request(core.UPDATE_CHOICE(choice_id), payload, "patch");
const deleteChoice: DeleteChoice = (choice_id) => loginEndpoint.request(core.DELETE_CHOICE(choice_id), null, "delete");
const createChoice: CreateChoice = (field_id, payload) => loginEndpoint.request(core.CREATE_CHOICE(field_id), payload, "post");
const createSchemaTemp: CreateSchema = (payload) => loginEndpoint.request(core.CREATE_SCHEMA_TEMP, payload, "post");

// - logs
const getObjectLogs = (payload) => loginEndpoint.request(core.GET_OBJECT_LOGS(payload.query), null, "post");
const getObjectLogsTemp = (payload) => loginEndpoint.request(core.GET_OBJECT_LOGS_TEMP(payload.query), null, "post");

export {
  // objects
  getSingleObject,
  createObject,
  getGroupedObjects,
  updateObject,
  getUnGroupedObjects,
  getSingleView,
  addObjectOwner,
  deleteObjectOwner,
  addObjectNewRelationObject,
  getObjectFilters,
  tempObjectCreate,
  moveObjectBetweenSchemas,
  updateObjectsOwnersFully,
  // views
  getMultipleView,
  getViews,
  getViewsElements,
  updateView,
  getSchemaElementView,
  moveField,
  editGroup,
  removeGroup,
  createGroup,
  // schemas
  getGroupedObjectsTest,
  createSchemaTemp,
  getSchemaFields,
  getRelationsSchema,
  addSchemaField,
  getSchemas,
  updateChoice,
  updateSchema,
  deleteChoice,
  createChoice,
  updateSchemaField,
  deleteSchemaField,
  // logs
  getObjectLogs,
  getObjectLogsTemp,
};
