import React from "react";
import "./index.css";
import { IconPaperclip, IconFileDownload, IconExternalLink } from "@tabler/icons-react";
import IconManager from "../../../../kit/Icons/IconManager";
import Spinner from "../../../../kit/Loaders/Spinner";
import { LINE_HEIGHT } from "../../../../../constants/ui-constants";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";

/**
 * color
 * fontSize
 * placeholder,
 * tooltip
 * value
 * style
 * inputStyle
 * icon
 *
 * readOnly
 * error
 * loading
 *
 * onBlur
 * onSubmit
 * onEnterPress
 */

class EditInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.value ? this.props.value : "",
    };
    this.element = null;
    this.prevValue = this.props.value;
    this.createComment = this.props.createComment;
    this.ref = null;
    this.deleteFile = this.props.deleteFile;
    this.sendFile = this.props.sendFile;
    this.isSendFile = this.props.isSendFile;
    this.blur = this.blur.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.focusInput = this.focusInput.bind(this);
    this.inputFileRef = React.createRef(null);
  }

  blur = () => {
    this.ref.blur();
  };

  onBlur = (event) => {
    event.stopPropagation()
    // this.setContentEditable(false);
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    this.onSubmit();
  };

  onSubmit = () => {
    const value = this.state.text;
    if (this.prevValue !== value) {
      this.prevValue = value;
      if (this.props.onSubmit) {
        this.props.onSubmit(value);
      }
    }
  };

  clearInput = () => {
    this.ref.value = "";
    this.setState({ text: "" });
  };
  resizeTextArea = (element) => {
    const fontSize = this.props.fontSize;
    const minHeight = fontSize ? fontSize * 1.4 : 15 * 1.4;
    // const scrollHeight = element.scrollHeight;
    element.style.height = "inherit";
    element.style.height = `${element.scrollHeight === 0 ? minHeight : element.scrollHeight}px`;
  };

  onKeyPress = (e) => {
    e.stopPropagation()
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      console.log("e", e);
      e.target.value += "\n";
      // this.setState({ text: `${this.state.text}\n` });
      e.target.style.height = e.target.scrollHeight + "px";
    }
    // this.resizeTextArea(e.target);
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.createComment(this.state.text);
      if (this.props.onEnterPress) {
        this.props.onEnterPress(e);
      }
      this.blur();
    }
  };

  componentDidMount() {
    if (this.props.value) {
      this.resizeTextArea(this.ref);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({ text: this.props.value }, () => this.resizeTextArea(this.ref));
    }
  }

  focusInput = () => {
    this.ref.focus();
  };

  handleOnChange = (e) => {
    this.setState({ text: e.target.value });
    this.resizeTextArea(e.target);
  };
  // validURL = () => {
  //   var pattern = new RegExp(
  //     "^(https?:\\/\\/)?" +
  //       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
  //       "((\\d{1,3}\\.){3}\\d{1,3}))" +
  //       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
  //       "(\\?[;&a-z\\d%_.~+=-]*)?" +
  //       "(\\#[-a-z\\d_]*)?$",
  //     "i"
  //   );
  //   return !!pattern.test(this.state.text);
  // };

  render() {
    const { fontSize, label, icon, isSendFile, loading, color, style, tooltip, error, inputStyle, value, readOnly, placeholder } = this.props;

    return (
      <EditWidgetIconLabelTemplate
        {...{
          label,
          fontSize,
          icon,
        }}>
        <div
          {...{
            onClick: this.focus,
            ...(tooltip && { title: tooltip }),
            className: `EditInput ${error ? "EditInput_error" : ""}`,
            style,
          }}>
          {!!loading && (
            <div
              {...{
                className: "EditInput_loading",
                style: {
                  right: -(fontSize * LINE_HEIGHT) - 6,
                  height: fontSize * LINE_HEIGHT,
                },
              }}>
              <Spinner {...{ loading: loading, size: fontSize }} />
            </div>
          )}
          {!!icon && !label && (
            <div
              {...{
                className: "EditInput_icon flex-row align-center",
                style: { height: fontSize * LINE_HEIGHT },
              }}>
              <IconManager {...{ icon, color, size: fontSize - 3 }} />
            </div>
          )}
          {isSendFile && (
            <>
              <input
                ref={this.inputFileRef}
                type="file"
                multiple={true}
                disabled={this.props.fileLoading}
                style={{ display: "none" }}
                onChange={(e) => this.sendFile(e)}
              />
              <div
                className="EditInput_sendFile"
                onClick={() => this.inputFileRef.current.click()}>
                <IconPaperclip className="Paperclip" />
              </div>
            </>
          )}
          {this.props.isLink && (
            <div
              onClick={() => window.open(`//${this.state.text.replace(/(^\w+:|^)\/\//, "")}`, "_blank")}
              style={{ cursor: "pointer", marginRight: "5px" }}>
              <IconExternalLink className="EditInput_link" />
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <textarea
              {...{
                style: {
                  ...inputStyle,
                  cursor: !readOnly ? "text" : "default",
                  opacity: readOnly ? 0.6 : 1,
                  fontSize,
                  color,
                },
                className: "EditInput_editableDiv",
                ref: (el) => (this.ref = el),
                placeholder,
                rows: 1,
                value: this.state.text,
                onChange: this.handleOnChange,
                onBlur: this.onBlur,
                onKeyPress: this.onKeyPress,
                disabled: readOnly,
              }}></textarea>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {this.isSendFile && this.props.filesUrl?.length
                ? this.props.filesUrl.map((file, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{ display: "flex", marginTop: "4px", justifyContent: "start", gap: "5px", alignItems: "center" }}>
                        <div
                          onClick={() => this.deleteFile(idx)}
                          {...{ className: "EditInput_deleteComment" }}>
                          &#10005;
                        </div>
                        <div style={{ height: "100%", display: "flex", fontSize: "14px", justifyContent: "center", alignItems: "center" }}>
                          {file.name}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          {/*<div*/}
          {/*  {...{*/}
          {/*    style: {*/}
          {/*      ...inputStyle,*/}
          {/*      cursor: !readOnly ? "text" : "default",*/}
          {/*      opacity: readOnly ? 0.6 : 1,*/}
          {/*      pointerEvents: readOnly ? "none" : "initial",*/}
          {/*      fontSize,*/}
          {/*      color,*/}
          {/*    },*/}
          {/*    contentEditable: !readOnly,*/}
          {/*    onBlur: this.onBlur,*/}
          {/*    onKeyPress: this.onKeyPress,*/}
          {/*    spellCheck: false,*/}
          {/*    placeholder,*/}
          {/*    className: "EditInput_editableDiv",*/}
          {/*    ref: (elem) => (this.ref = elem),*/}
          {/*  }}>*/}
          {/*  {value ? value : ""}*/}
          {/*</div>*/}
        </div>
      </EditWidgetIconLabelTemplate>
    );
  }
}

EditInput.defaultProps = {
  color: "var(--text-tenth)",
  fontSize: 15,
  placeholder: "Текст",
  icon: null,
  value: "",
  tooltip: null,
  readOnly: false,
  error: false,
  loading: false,
};

export default EditInput;
