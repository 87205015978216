import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: "https://df7e2aa97adc413898ad9b968fd6e582@o205457.ingest.sentry.io/4504564367818752",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);
