import React from "react";
import MenuElement from "../MenuElement";
import TelephonyCustomNumber from "../../TelephonyCustomNumber";

const BottomContainer = ({ logoutAction }) => {
  return (
    <div
      {...{
        className: "SideMenu_linksListBottom flex-column justify-center",
      }}>
      <TelephonyCustomNumber />
      <MenuElement
        {...{
          icon: "info-circle",
          path: "/settings",
          active: false,
        }}
      />
      <MenuElement
        {...{
          onClick: () => logoutAction(),
          icon: "arrow-bar-left",
          path: null,
          active: false,
        }}
      />
    </div>
  );
};

export default BottomContainer;
