import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import { EXTENSION_STAGE_ANIMATION } from "../../../../../constants/framer-motion-animations";
import { DRAG_N_DROP_MOVE_TYPES, setDragNDropSectionData } from "../../../../templates/DragAndDropNativeTemplate/drag-n-drop";
import { useDispatch, useSelector } from "react-redux";
import SelectDefaultPlaceholder from "../../../../kit/Selects/SelectDefaultPlaceholder";
import DefaultInputPlaceholder from "../../../../kit/Inputs/DefaultInputPlaceholder";
import DefaultButton from "../../../../kit/Buttons/DefaultButton";
import { setMultiplyViewActiveStageIsActive, setMultiplyViewStageIsActive } from "../../../../../store/reducers/extensionsReducer";
import { isValue } from "../../../../../helpers/values-validator";
import { finishObjectExtensionStage } from "../../../../../api/routes/extensions";
import { BAD, SUCCESS } from "../../../../../helpers/response-service";
import { setNotification } from "../../../../../store/actions/appActions";
import { FINISH_STAGE_BAD, FINISH_STAGE_SUCCESS } from "../../../../../constants/notification-texts";
import _ from "lodash";

const FinalStage = (props) => {
  const { stageKey, dragNDrop } = props;

  const [formState, setFormState] = React.useState(FINAL_STAGE_FORM);
  const [loading, setLoading] = React.useState(false);

  const ref = React.useRef(null);
  const createStoreAction = useDispatch();
  const data = useSelector((state) => state.extensions.__stageExtensionConfig.stages[stageKey]);

  React.useEffect(() => {
    if (ref.current) {
      Object.assign(ref.current, setDragNDropSectionData(data, DRAG_N_DROP_MOVE_TYPES.FINAL_STAGE));
    }
  }, []);

  const setForm = (key, { error = undefined, value = undefined }) => {
    setFormState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        ...(error !== undefined && { error }),
        ...(value !== undefined && { value }),
      },
    }));
  };

  const cancelFinalStage = () => {
    resetFinalStageAction();
    dragNDrop.reverseDragAction();
  };

  const resetFinalStageAction = () => {
    createStoreAction(
      setMultiplyViewActiveStageIsActive({
        type: data.key,
        value: false,
        selected: null,
      })
    );
    createStoreAction(setMultiplyViewStageIsActive(false));
  };

  const notifyApp = (notification) => {
    createStoreAction(setNotification(notification));
  };

  const confirmFinalStageAction = () => {
    let valid = true;
    const { drag_item_id } = dragNDrop.prevAcitionInfo;
    Object.keys(formState).map((key) => {
      if (!isValue(formState[key].value)) {
        valid = false;
        setForm(key, { error: true });
      }
    });
    if (valid) {
      const payload = {
        query: {
          path: formState.stage.value[0].choice_id,
          params: {
            object_id: drag_item_id,
          },
        },
        data: {
          text: formState.text.value,
        },
      };
      payload.query.params = new URLSearchParams(payload.query.params).toString();
      setLoading(true);
      finishObjectExtensionStage(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              notifyApp(FINISH_STAGE_SUCCESS);
              resetFinalStageAction();
              break;
            case BAD:
              notifyApp(FINISH_STAGE_BAD);
              cancelFinalStage();
          }
        })
        .catch((e) => {
          notifyApp(FINISH_STAGE_BAD);
          cancelFinalStage();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (data && _.isArray(data.data) && data.data.length > 0) {
    return (
      <motion.div
        {...{
          className: "FinalStage",
          style: {
            width: 300,
            height: data.hasItem ? "auto" : 60,
          },
          ...EXTENSION_STAGE_ANIMATION(true),
        }}>
        {!data.hasItem && (
          <div {...{ ref, className: "FinalStage_Droppable boardElementsWrapper" }}>
            <div {...{ className: "FinalStage_DroppableItem droppable" }}></div>
          </div>
        )}

        {data.hasItem && (
          <div {...{ className: "FinalStage_hasItem" }}>
            <div {...{ className: "FinalStage_contentColor", style: { backgroundColor: data.color } }}></div>
            <div {...{ className: "FinalStage_hasItemTitle" }}>{data.name}</div>
            <div {...{ className: "FinalStage_hasItemForm" }}>
              <SelectDefaultPlaceholder
                {...{
                  data: data.data,
                  error: formState.stage.error,
                  values: formState.stage.value,
                  placeholder: "Выберите стадию",
                  onSubmit: (value) => setForm("stage", { value, error: false }),
                  amount: 1,
                }}
              />
              <DefaultInputPlaceholder
                {...{
                  style: { marginTop: 10 },
                  error: formState.text.error,
                  value: formState.text.value,
                  onChange: (value) => setForm("text", { value, error: false }),
                  placeholder: "Опишите причину",
                }}
              />
              <div {...{ className: "flex-row justify-between", style: { marginTop: 100 } }}>
                <DefaultButton
                  {...{
                    onClick: cancelFinalStage,
                    text: "Отмена",
                    border: true,
                    disabled: loading,
                    width: "36%",
                    height: 45,
                  }}
                />
                <DefaultButton
                  {...{
                    onClick: confirmFinalStageAction,
                    text: "Подтвердить",
                    loading,
                    disabled: loading,
                    width: "60%",
                    height: 45,
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {!data.hasItem && (
          <div {...{ className: "FinalStage_content" }}>
            <div {...{ className: "FinalStage_contentColor", style: { backgroundColor: data.color } }}></div>
            <div {...{ className: "FinalStage_contentInner" }}>{data.name}</div>
          </div>
        )}
      </motion.div>
    );
  }
};

export default FinalStage;

const FINAL_STAGE_FORM = {
  stage: {
    value: null,
    error: false,
  },
  text: {
    value: "",
    error: false,
  },
};
