import React from "react";
import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../templates/ClickOutsideBoundaryTemplate";
import ConfigIcon from "../../kit/Icons/ConfigIcon";
import { BiPlus } from "react-icons/bi";
import DefaultButton from "../../kit/Buttons/DefaultButton";
import { addObjectNewRelationObject, createObject, getUnGroupedObjects } from "../../../api/routes/objects_core";
import { BAD, SUCCESS } from "../../../helpers/response-service";
import SingleObjectEditFieldsIteration from "../SingleObject/SingleObjectEditFieldsIteration";
import { isFieldValueValid } from "../../../helpers/values-validator";
import { formatFieldForCreate } from "../../../helpers/field-helper";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../store/actions/appActions";
import _ from "lodash";
import { FAILED_CREATE_FAST_OBJECT, FAILED_RELATE_OBJECT_TO_PARENT } from "../../../constants/notification-texts";

const FastObjectCreate = (props) => {
  const view_type = "CARD";

  const { includeFields, schemaFields, schema_id, onCreate, containerRef, parentObjectId, parentObjectSchemaId, schemaKey } = props;

  // const formRef = React.useRef(null);
  const [fields, setFields] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(null);

  const createStoreAction = useDispatch();

  const header = () => {
    return (
      <div>
        <ConfigIcon {...{ color: "var(--gray1)", size: 24 }}>
          <BiPlus />
        </ConfigIcon>
      </div>
    );
  };

  const resetFields = () => {
    if (fields) {
      Object.keys(fields).forEach((field_key) => {
        handleField(field_key, { error: false, values: null });
      });
    }
  };

  const removeUnRequiredFields = (fields) => {
    if (fields && _.isArray(fields)) {
      return fields.filter((field) => field.required === true);
    }
    return null;
  };

  const setStateFields = () => {
    if (schemaFields && schemaFields.fields) {
      const filteredFields = removeUnRequiredFields(schemaFields.fields);
      const data = filteredFields.map((field) => ({ ...field, ...(field.default && { values: [field.default] }) }));
      if (data) {
        setFields(Object.assign({}, data));
      }
    } else {
      createStoreAction(
        setNotification({
          text: "Для данного типа схемы нет возможности быстрого создания объекта",
          type: "warning",
        })
      );
      handleDisableNewObject();
    }
  };

  React.useEffect(() => {
    if (!isOpen) {
      resetFields();
    } else {
      setStateFields();
    }
  }, [isOpen]);

  const handleField = (key, { values = undefined, error = undefined }) => {
    setFields((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        ...(values !== undefined && { values }),
        ...(error !== undefined && { error }),
      },
    }));
  };

  const getFormattedFieldsCollection = (fields) => {
    let fieldsCollection = [];
    includeFields && Object.assign(fieldsCollection, includeFields);
    Object.keys(fields).forEach((field_key) => {
      const field = fields[field_key];
      fieldsCollection.push(formatFieldForCreate(field));
    });
    return fieldsCollection;
  };

  const handleDisableNewObject = () => {
    containerRef.current.handleContainer();
  };

  const handleSubmitNewObject = () => {
    let valid = true;
    Object.keys(fields).forEach((field_key) => {
      const field = fields[field_key];
      const isFieldValid = isFieldValueValid(field.required, field.type, field.values);
      if (!isFieldValid) {
        valid = false;
        handleField(field_key, { error: true });
      }
    });
    if (valid) {
      const payload = {
        query: {
          schema_id,
        },
        data: {
          data: getFormattedFieldsCollection(fields),
        },
      };
      payload.query = new URLSearchParams(payload.query).toString();
      setLoading(true);

      // 1 - create new object
      // 2 - get fully created object in CARD view
      // 3 - если have parent id - connect created object to parent
      // 4 - return fully created object in CARD view

      // 1.
      createObject(payload)
        .then((result) => {
          if (result.kind === SUCCESS) {
            handleDisableNewObject();
            // 2.
            getSimpleObject(result.data.id).then((simple_object) => {
              // 3.
              if (parentObjectId && parentObjectSchemaId && result.data.id) {
                connectCreatedObjectToParent(result.data.id)
                  .then(() => {
                    // 4.
                    onCreate(simple_object);
                  })
                  .catch(() => {
                    createStoreAction(setNotification(FAILED_RELATE_OBJECT_TO_PARENT));
                  });
              } else {
                // 4.
                onCreate(simple_object);
              }
            });
          } else {
            createStoreAction(setNotification(FAILED_CREATE_FAST_OBJECT));
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    }
  };

  const connectCreatedObjectToParent = (created_object_id) => {
    return new Promise((resolve, reject) => {
      const relationRequestPayload = {
        query: {
          object_id: parentObjectId,
          params: {
            related_object_id: created_object_id,
          },
        },
      };
      relationRequestPayload.query.params = new URLSearchParams(relationRequestPayload.query.params).toString();
      addObjectNewRelationObject(relationRequestPayload).then((result) => {
        switch (result.kind) {
          case SUCCESS:
            resolve();
            break;
          case BAD:
            reject();
        }
      });
    });
  };

  const getSimpleObject = (created_object_id) => {
    return new Promise((resolve, reject) => {
      if (!created_object_id) {
        reject({ error: true });
      }
      const payload = {
        query: {
          schema_id,
          view_type,
          page: 0,
          limit: 1,
        },
        data: {
          ids: [created_object_id * 1],
        },
      };
      payload.query = new URLSearchParams(payload.query).toString();
      getUnGroupedObjects(payload).then((result) => {
        switch (result.kind) {
          case SUCCESS:
            resolve(getObjectByIndex(result.data));
            break;
          case BAD:
            reject(result.data);
        }
      });
    });
  };

  const getObjectByIndex = (data, index = 0) => {
    return data[index];
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        onChange: (value) => setIsOpen(value),
        header,
        ref: containerRef,
      }}>
      <div
        {...{
          className: "FastObjectCreateContainer flex-column justify-between",
        }}>
        <SingleObjectEditFieldsIteration
          {...{
            fields,
            excludeIds: includeFields ? includeFields.map((item) => item.field_id) : [],
            includeExtensions: false,
            handleScrollableDivs: false,
            schemaKey,
            onSubmit: (key, field, values) => handleField(key, { values, error: false }),
          }}
        />
        <div
          {...{
            className: "FastObjectCreateContainer_createButtons flex-row align-center justify-end",
          }}>
          <div
            {...{
              className: "FastObjectCreateContainer_createButtons_cancel transition-default",
              onClick: () => handleDisableNewObject(),
            }}>
            Отмена
          </div>
          <DefaultButton
            {...{
              disabled: loading,
              loading,
              onClick: handleSubmitNewObject,
              width: 120,
              height: 45,
              text: "Создать",
            }}
          />
        </div>
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

export default FastObjectCreate;

FastObjectCreate.defaultProps = {};
