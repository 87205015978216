import React from "react";
import "./index.css";
import ConfigIcon from "../../Icons/ConfigIcon";
import { FaFlag } from "react-icons/fa";
import _ from "lodash";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";

const defaultColor = "#DBDBEB";

const SingleObjectPriority = (props) => {
	const { data, value, style, onChange, onSubmit } = props;

	const [state, setState] = React.useState(value ? priorityType[value] : null);
	const ref = React.useRef(null);

	React.useEffect(() => {
		setState(priorityType[value]);
	}, [value]);

	const header = () => {
		return (
			<div
				{...{
					className: `SingleObjectPriority ${!state ? "SingleObjectPriority_Empty" : ""}`,
					style: {
						borderColor: state ? state.color : defaultColor,
						borderStyle: state ? "solid" : "dashed",
					},
				}}
			>
				<ConfigIcon {...{ color: state ? state.color : defaultColor, size: 14 }}>
					<FaFlag />
				</ConfigIcon>
			</div>
		);
	};

	const handleSelectValue = (value) => {
		onChange([value]);
		onSubmit([value]);
		ref.current.handleContainer();
	};

	return (
		<ClickOutsideBoundaryTemplate
			{...{
				style,
				ref,
				header,
			}}
		>
			<div {...{ className: "SelectDefaultUnderline_variantsInner" }}>
				{data && _.isArray(data)
					? data.map((item, key) => {
							return (
								<div
									{...{
										key,
										className: `SelectDefaultUnderline_variantsItem ${value && value.includes(item) ? "SelectDefaultUnderline_variantsItemActive" : ""}`,
										onClick: () => handleSelectValue(item),
									}}
								>
									<span {...{ className: "text-dotted-end" }}>{item}</span>
								</div>
							);
					  })
					: null}
			</div>
		</ClickOutsideBoundaryTemplate>
	);
};

export default SingleObjectPriority;

const priorityType = {
	TOP: {
		color: "#C70039",
	},
	MIDDLE: {
		color: "#FFC300",
	},
	LOW: {
		color: "#8A9A5B",
	},
};
