import React from "react";
import { isValue } from "../../../../../helpers/values-validator";
import SelectTag from "../../../../kit/Selects/SelectTag";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";

class EditTags extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, fontSize, icon, values, onSubmit, readOnly } = this.props;

    return (
      <EditWidgetIconLabelTemplate
        {...{
          label,
          fontSize,
          icon,
        }}>
        <SelectTag
          {...{
            onChange: !readOnly
              ? (values) => {
                  onSubmit(values);
                }
              : undefined,
            style: { opacity: readOnly ? 1 : 0.8, cursor: readOnly ? "default" : "pointer" },
            values,
          }}
        />
      </EditWidgetIconLabelTemplate>
    );
  }
}

export default EditTags;
