import React from "react";
import "./index.css";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";
import Spinner from "../../../../kit/Loaders/Spinner";
import { LINE_HEIGHT } from "../../../../../constants/ui-constants";
import IconManager from "../../../../kit/Icons/IconManager";
import { formatPrice } from "../../../../../helpers/price-formatter";

class EditPrice extends React.Component {
  constructor(props) {
    super(props);
    this.ref = null;
    this.state = {
      value: this.props.value,
    };
  }

  setValue = (value) => {
    this.setState({ value });
  };

  handleChangeValue = (value) => {
    const item = formatPrice(value);
    this.setValue(item);
  };

  handleSubmitValue = (value) => {
    const onSubmit = this.props.onSubmit;
    const item = formatPrice(value);
    if (onSubmit && this.props.value !== value) {
      onSubmit(item);
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.ref.blur();
    }
  };

  render() {
    const { placeholder, fontSize, icon, label, loading, error, readOnly, color } = this.props;

    return (
      <EditWidgetIconLabelTemplate
        {...{
          fontSize,
          label,
          icon,
        }}>
        <div
          {...{
            className: `EditPrice ${error ? "EditPrice_error" : ""}`,
          }}>
          <input
            {...{
              readOnly: readOnly || loading,
              ref: (elem) => (this.ref = elem),
              style: {
                fontSize,
                color,
                height: 1.4 * fontSize,
                paddingLeft: !!icon && !label ? 1.4 * fontSize + 2 : 0,
                cursor: readOnly ? "default" : "text",
                opacity: readOnly ? 0.6 : 1,
                pointerEvents: readOnly ? "none" : "initial",
              },
              placeholder,
              onKeyPress: (e) => this.handleKeyPress(e),
              onBlur: (e) => this.handleSubmitValue(e.target.value),
              value: formatPrice(this.state.value) || "",
              onChange: (e) => this.handleChangeValue(e.target.value),
              className: "EditPrice_input",
            }}
          />
          {loading && (
            <div
              {...{
                className: "EditPrice_loading flex-row align-center",
                style: {
                  height: 1.4 * fontSize,
                  right: -(fontSize * LINE_HEIGHT) - 6,
                },
              }}>
              <Spinner {...{ loading, size: fontSize }} />
            </div>
          )}
          <div
            {...{
              className: "EditPrice_priceIcon flex-row align-center",
              style: { height: 1.4 * fontSize, right: -2 },
            }}>
            <IconManager
              {...{
                icon: "currency-rubel",
                color: "var(--text-sixth)",
                size: fontSize - 3,
              }}
            />
          </div>
          {!!icon && !label && (
            <div
              {...{
                className: "EditPrice_icon flex-row align-center",
                style: { height: 1.4 * fontSize },
              }}>
              <IconManager {...{ icon, color, size: fontSize - 3 }} />
            </div>
          )}
        </div>
      </EditWidgetIconLabelTemplate>
    );
  }
}

EditPrice.defaultProps = {
  placeholder: "100 000 000",
  label: null,
  icon: "coin",
  fontSize: 15,
  error: false,
  loading: false,
  color: "var(--text-tenth)",
};

export default EditPrice;
