import React from "react";
import LogsContainer from "../../LogsContainer";
import { useParams } from "react-router-dom";
import { EXTENSIONS } from "../../../extensions";
import _ from "lodash";

const SingleObjectExtensions = (props) => {
	const { object_id } = useParams();
	const { data } = props

	return (
		<div>
			{
				Object.keys(data).map((extension_key) => {
					if(extension_key === extensions.HISTORY) {
						return <LogsContainer {...{key: _.uniqueId(), object_id }} />
					} else {
						return (
							<div {...{key: _.uniqueId()}}>
								{EXTENSIONS[extension_key.toUpperCase()].SINGLE_VIEW.component({data: data[extension_key]})}
							</div>
						)
					}
				})
			}
		</div>
	)
}

export default SingleObjectExtensions;

const extensions = {
	HISTORY: "HISTORY",
	STAGE: "STAGE",
	DEADLINE: "DEADLINE"
}
