import { onMessage } from "firebase/messaging";
import { getFcmToken, messaging } from "../firebase";
import { toast } from "react-toastify";

export const pushNotificationHelper = (pushAction) => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getFcmToken().then((token) => {
        if (token) {
          pushAction({ token: `token=${token}` });
        }
      }).catch(console.log);
    }
  });

  const startNotificationSound = () => {
    document.notificationAudioPlayer = new Audio('https://cdn.ugravity.org/track-up/mp3/alert.mp3');
    document.notificationAudioPlayer.play().finally(() => {
      document.notificationAudioPlayer = null
    })
  }

  onMessage(messaging, (payload) => {
    if(!document.notificationAudioPlayer) {
      startNotificationSound()
    } else {
      document.notificationAudioPlayer.pause()
      document.notificationAudioPlayer = null
      startNotificationSound()
    }

    toast(`${payload.notification.title} - ${payload.notification.body}`, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      bodyClassName: "TextNotificationColor",
      progressClassName: "ProgressNotificationColor",
      onClick: () => window.open(payload.data.path, "_blank").focus(),
    });
  });
};
