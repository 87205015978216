import React from "react";
import "./index.css";
import SingleObjectUsers from "../../../kit/Selects/SingleObjectUsers";
import { validateValuesByType } from "../../../../helpers/values-validator";
import { updateObject } from "../../../../api/routes/objects_core";
import ViewIteration from "./FieldsIterations/ViewIteration";
import ViewDefault from "../../Widgets/View/ViewDefault";
import { VIEW_FONT_SIZE_DEFAULT } from "../../../../constants/ui-constants";
import EditIteration from "./FieldsIterations/EditIteration";
import { Link } from "react-router-dom";
import { EXTENSIONS } from "../../../extensions";
import { useSelector } from "react-redux";
import _ from "lodash";

const ObjectCard = (props) => {
  const { data, style, editable, users, isShowId, schemaKey } = props;
  const campaign_users = useSelector((state) => state.app.campaign_users);

  const [fields, setFields] = React.useState(data.fields);

  const handleUpdateField = (field_type, field_id, values) => {
    const payload = {
      query: data.id,
      data: [
        {
          field_id,
          values: validateValuesByType(field_type, values),
        },
      ],
    };
    updateObject(payload).catch((e) => console.log(e));
  };

  const setField = (row_key, field_key, values) => {
    const localFields = [...fields];
    localFields.map((row, state_row_key) => {
      if (row_key === state_row_key) {
        row.map((field, state_field_key) => {
          if (field_key === state_field_key) {
            localFields[state_row_key][state_field_key].values = values;
          }
        });
      }
    });
    setFields(localFields);
  };

  const onSubmitField = (row_key, field_key, { values, field_id, type }) => {
    setField(row_key, field_key, values);
    handleUpdateField(type, field_id, values);
  };

  const onChange = ({ row_key, field_key, field, value }) => {
    setField(row_key, field_key, value);
  };

  const onSubmit = ({ row_key, field_key, field, value }) => {
    onSubmitField(row_key, field_key, {
      values: value,
      field_id: field.id,
      type: field.type,
    });
  };

  return (
    <div
      {...{
        className: "ObjectCard flex-column",
        style: { ...style },
      }}>
      <div {...{ className: "ObjectCard_fields flex-column" }}>
        {editable ? <EditIteration {...{ fields, onChange, onSubmit, object_id: data.id, schemaKey }} /> : <ViewIteration {...{ fields }} />}
        {isShowId && (
          <Link {...{ to: `/${schemaKey}/${data.id}`, style: { marginTop: 10 } }}>
            <ViewDefault
              {...{
                color: "var(--gray1)",
                fontSize: VIEW_FONT_SIZE_DEFAULT - 2,
                mask: null,
                text: data.id,
              }}
            />
          </Link>
        )}
      </div>
      {users && (
        <div {...{ className: "ObjectCard_users" }}>
          <SingleObjectUsers
            {...{
              size: 24,
              object_id: data.id,
              readOnly: !editable,
              object_users: data.users,
              campaign_users,
            }}
          />
        </div>
      )}
      {data.extensions && (
        <div {...{ className: "ObjectCard_extensions flex-row" }}>
          {Object.keys(data.extensions).map((extension) => {
            const extensionComponent = EXTENSIONS[extension.toUpperCase()];
            if (extensionComponent && extensionComponent.ELEMENT_VIEW.component()) {
              return <div {...{ key: _.uniqueId() }}>{extensionComponent.ELEMENT_VIEW.component(data.extensions[extension])}</div>;
            }
          })}
        </div>
      )}
    </div>
  );
};

export default ObjectCard;

ObjectCard.defaultProps = {
  data: null,
  style: null,
  isGrouped: false,
  editable: false,
  actions: false,
  users: true,
  isShowId: true,
  id: 0,
};
