import React from "react";
import './index.css'
import { ClickOutsideBoundaryTemplate } from "../../../../templates/ClickOutsideBoundaryTemplate";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";
import IconManager from "../../../../kit/Icons/IconManager";
import { isValue } from "../../../../../helpers/values-validator";
import { COLORS } from "../../../../../constants/types";

class EditIcon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null
		};
	}

	header = (value) => {
		if(isValue(value)) {
			return (
				<div {...{className: 'EditIcon_value'}}>
					<IconManager {...{
						icon: value[0].icon,
						size: 14,
						color: '#DBDBEB'
					}}/>
					<div {...{
						className: 'EditIcon_valueColor',
						style: {backgroundColor: COLORS[value[0].color]}
					}}></div>
				</div>
			);
		} else return null
	};

	componentDidMount() {
		const value_string = this.props.value
		if(value_string) {
			const data = this.props.data
			this.setState({
				value: data.filter(item => item.name === value_string)
			})
		}
	}

	render() {

		const {
			label,
			fontSize,
			icon,
			readOnly
		} = this.props;

		return (
			<EditWidgetIconLabelTemplate
				{...{
					label,
					fontSize,
					icon
				}}
			>
				<ClickOutsideBoundaryTemplate {...{
					header: () => this.header(this.state.value),
					disabled: readOnly
				}}>
					<div></div>
				</ClickOutsideBoundaryTemplate>
			</EditWidgetIconLabelTemplate>
		);
	}
}

export default EditIcon;
