import React from "react";
import "./index.css";
import { EDIT_WIDGETS_PULL } from "../../../../../../constants/widgets";
import { isValue } from "../../../../../../helpers/values-validator";
import { EDIT_FONT_SIZE_DEFAULT } from "../../../../../../constants/ui-constants";
import { EXTENSIONS } from "../../../../../extensions";
import _ from 'lodash';

const EditIteration = (props) => {
  const { fields, isGrouped, onChange, onSubmit, acceptEmptyValues, object_id, schemaKey } = props;

  const onChangeListener = (data) => {
    onChange(data);
  };

  const onSubmitListener = (data) => {
    onSubmit(data);
  };

  const ACCEPT_FIELDS = Object.keys(EDIT_WIDGETS_PULL(false, null, null));

  if (fields) {
    return (
      <div
        {...{
          className: `flex-column`
        }}>
        {fields.map((row, row_key) => <div
          {...{
            key: _.uniqueId("__view__row"),
            className: `EditIteration_row ${isGrouped ? "flex-row justify-between" : "flex-column"}`
          }}>
          {row &&
            row.map((field, field_key) => {
              const EDIT_WIDGET = field.edit_widget.toUpperCase();
              const isShow = acceptEmptyValues ? true : isValue(field.values);
              const extension = field.extensions_type;
              if (isShow && ACCEPT_FIELDS.includes(EDIT_WIDGET)) {
                if (extension) {
                  return (
                    <div
                      {...{
                        key: _.uniqueId("__edit__row__item")
                      }}>
                      {EXTENSIONS[extension]?.FIELD_VIEW?.component({
                        data: field,
                        object_id,
                        schemaKey
                      })}
                    </div>
                  );
                } else
                  return (
                    <div
                      {...{
                        style: { display: "block" },
                        key: _.uniqueId("__edit__row__item"),
                        className: "EditIteration_rowItem"
                      }}>
                      {EDIT_WIDGETS_PULL(
                        true,
                        schemaKey,
                        (field, value) =>
                          onChangeListener({
                            row_key,
                            field_key,
                            field,
                            value
                          }),
                        (field, value) =>
                          onSubmitListener({
                            row_key,
                            field_key,
                            field,
                            value
                          }),
                        EDIT_FONT_SIZE_DEFAULT - 2
                      )[EDIT_WIDGET].component(field)}
                    </div>
                  );
              } else return null;
            })}
        </div>)}
      </div>
    );
  } else return null;
};

export default EditIteration;

EditIteration.defaultProps = {
  fields: null,
  isGrouped: false,
  isShowId: true,
  id: "0",
  acceptEmptyValues: true
};
