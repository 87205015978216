import React from "react";
import IconManager from "../components/kit/Icons/IconManager";
import DefaultInputPlaceholder from "../components/kit/Inputs/DefaultInputPlaceholder";
import { isValue } from "../helpers/values-validator";
import SelectDefaultPlaceholder from "../components/kit/Selects/SelectDefaultPlaceholder";
import SelectDatePlaceholder from "../components/kit/Selects/SelectDatePlaceholder";
import PhoneInputPlaceholder from "../components/kit/Inputs/PhoneInputPlaceholder";

export const NOTIFICATION_TYPES = {
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success",
  UPDATED: "updated",
  INFO: "info",
};

export const API_RESPONSE_CODES = {
  SERVER_ERROR: 500,
  VALIDATION_ERROR: 422,
  CLIENT_ERROR: 400,
  AUTH_ERROR: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNDEFINED_ERROR: 0, // CORS
};

export const OBJECT_VIEW_TYPES = {
  CARD: "CARD",
  ROW: "ROW",
  STR: "STR",
};

export enum VIEW_TYPES {
  BILLBOARD = "BILLBOARD",
  LIST = "LIST",
  TABLE = "TABLE",
}

export enum EditWidgetsEnum {
  INPUT = "INPUT",
  DROPDOWN = "DROPDOWN",
  TEXTAREA = "TEXTAREA",
  PRICE = "PRICE",
  DATE = "DATE",
  TIME = "TIME",
  DATETIME = "DATETIME",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  LINK = "LINK",
  CHECKBOX = "CHECKBOX",
  TAG = "TAG",
}

export enum ViewWidgetsEnum {
  DEFAULT = "DEFAULT",
  PRICE = "PRICE",
  DATE = "DATE",
  TIME = "TIME",
  DATETIME = "DATETIME",
  PHONE = "PHONE",
  LINK = "LINK",
  TAG = "TAG",
}

export const DATE_WIDGETS = {
  allow: [EditWidgetsEnum.DATE, EditWidgetsEnum.DATETIME, EditWidgetsEnum.TIME],
  DATE: {
    date: true,
    time: false,
  },
  TIME: {
    date: false,
    time: true,
  },
  DATETIME: {
    date: true,
    time: true,
  },
};

export const FIELD_TYPES = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  PRICE: "PRICE",
  BOOL: "BOOL",
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  LINK: "LINK",
  DATETIME: "DATETIME",
};

export const DESKS_ARRAY = [
  { id: 1, name: "Продажи" },
  { id: 2, name: "Ипотека" },
  { id: 3, name: "Самолёт" },
];

export const VIEW_TYPES_ICONS = {
  BILLBOARD: <IconManager {...{ icon: "layout-kanban", color: "var(text-seventh)", size: 14 }} />,
  LIST: <IconManager {...{ icon: "layout-list", color: "var(text-seventh)", size: 14 }} />,
  TABLE: <IconManager {...{ icon: "table", color: "var(text-seventh)", size: 14 }} />,
};

export const COLORS = {
  BLUE100: "#3D75DB",
  BLUE300: "#3848F2",
  BLUE600: "#5635E8",

  VIOLET100: "#7338E8",
  VIOLET300: "#AB3AF2",
  VIOLET600: "#F23ABE",

  RED100: "#E83E3C",
  RED300: "#F25B3F",
  RED600: "#DB7144",

  YELLOW100: "#E8A738",
  YELLOW300: "#F2C13A",
  YELLOW600: "#F2E63A",

  GREEN100: "#5EE843",
  GREEN300: "#46F260",
  GREEN600: "#46F2C1",

  BLACK100: "#161717",
  BLACK300: "#707070",
  BLACK600: "#c5c5c5",

  GRAY100: "#dbdbeb",
  GRAY300: "#aeaec7",
  GRAY600: "#6e7689",
};

export const ElementColors = {
  0: COLORS.BLUE100,
  1: COLORS.BLUE300,
  2: COLORS.BLUE600,
  3: COLORS.VIOLET100,
  4: COLORS.VIOLET300,
  5: COLORS.VIOLET600,
  6: COLORS.RED100,
  7: COLORS.RED300,
  8: COLORS.RED600,

  9: COLORS.YELLOW100,
  10: COLORS.YELLOW300,
  11: COLORS.YELLOW600,
  12: COLORS.GREEN100,
  13: COLORS.GREEN300,
  14: COLORS.GREEN600,
  15: COLORS.BLACK100,
  16: COLORS.BLACK300,
  17: COLORS.BLUE100,
};

// SINGLE OBJECT

/**
 * - This is template for construct full single object layout by columns
 * - This config contains all info about groups, objects and appExtensions
 * - "width" param is in percent (%)
 */
export const COLUMN_STYLE_CONFIGS = {
  LEFT: {
    width: `${30}%`,
    paddingLeft: 14,
    paddingRight: 7,
    paddingTop: 14,
  },
  CENTER: {
    width: `${45}%`,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 14,
    paddingBottom: 14,
  },
  RIGHT: {
    width: `${25}%`,
    paddingLeft: 7,
    paddingRight: 14,
    paddingTop: 14,
  },
};

export const SINGLE_OBJECT_KEYS = {
  __objectMainElement: "main_element",
  __objectGroups: "groups",
  __objectRelationObjects: "nested_objects",
  __objectAppExtensions: "extensions",
};

export const SINGLE_OBJECT_COLUMNS_TEMPLATE = {
  LEFT: {
    styleConfig: COLUMN_STYLE_CONFIGS.LEFT,
    children: {
      __objectMainElement: "__singleObjectMainElement", // only for LEFT column
      __objectGroups: null, // only for LEFT column
      __objectRelationObjects: null,
      __objectAppExtensions: null,
    },
  },
  CENTER: {
    styleConfig: COLUMN_STYLE_CONFIGS.CENTER,
    children: {
      __objectRelationObjects: null,
      __objectAppExtensions: {
        HISTORY: true,
      },
    },
  },
  RIGHT: {
    styleConfig: COLUMN_STYLE_CONFIGS.RIGHT,
    children: {
      __objectRelationObjects: null,
      __objectAppExtensions: null,
    },
  },
};

export enum ROLE_TYPES_ENUM {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  REGULAR = "REGULAR",
}
