import React from "react";
import "./index.css";
import { ELEMENT_VIEW_STYLE_CONFIG } from "../../../index";
import IconManager from "../../../../kit/Icons/IconManager";

import _ from "lodash";

const WhatsAppExtension_ElementView = (props) => {
  const { __pact_message_count_wa__ } = props;

  const getMessagesCount = () => {
    if (!__pact_message_count_wa__ || !_.isArray(__pact_message_count_wa__)) {
      return 0;
    }
    return __pact_message_count_wa__.reduce((sum, item) => sum + item.values[0] * 1, 0);
  };

  if (getMessagesCount() > 0) {
    return (
      <div {...{ className: "flex-row margin-right-6 align-center" }}>
        <div {...{ style: { ...ELEMENT_VIEW_STYLE_CONFIG.icon, className: "WhatsAppExtension_ElementView flex-row" } }}>
          <IconManager
            {...{
              icon: "brand-whatsapp",
              size: 18,
              color: "#DBDBEB",
            }}
          />
          {/*<div {...{*/}
          {/*	style: {...ELEMENT_VIEW_STYLE_CONFIG.notification, backgroundColor: COLORS.RED100}*/}
          {/*}}></div>*/}
          <div {...{ className: "WhatsAppExtension_ElementViewCount margin-left-3" }}>{getMessagesCount() > 9 ? "9+" : getMessagesCount()}</div>
        </div>
      </div>
    );
  }
  return null;
};

export default WhatsAppExtension_ElementView;
