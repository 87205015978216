import React from "react";
import "./index.css";
import Tag from "../../../elements/Tags/Tag";
import ConfigIcon from "../../Icons/ConfigIcon";
import { HiPlusSm } from "react-icons/hi";
import EditableDiv from "../../Inputs/EditableDiv";
import _ from "lodash";

const SelectTag = (props) => {
	const { wrapped = true, style, readOnly = false, onChange, values = null, color = "var(--text-tenth)" } = props;

	// const [values, setValues] = React.useState(null)
	// const onChange = (data) => {
	//         setValues(data)
	// }

	const [isCreate, setIsCreate] = React.useState(false);
	const [tag, setTag] = React.useState("");
	const ref = React.useRef(null);

	React.useEffect(() => {
		if (ref.current && isCreate) {
			ref.current.click();
		}
	}, [isCreate]);

	const handleTags = (text, isAdd) => {
		if (text) {
			if (isAdd) {
				if (_.isArray(values) && values[0]) {
					handleValues([...values, text]);
				} else handleValues([text]);
			} else {
				handleValues(values.filter((item) => item !== text));
			}
		}
	};

	const handleValues = (values) => {
		if (onChange) {
			onChange(values);
		}
	};

	const handleCreate = (text) => {
		handleTags(text, true);
		setIsCreate(false);
		ref.current.innerText = "";
	};

	const handleDeleteTag = (tag) => {
		handleTags(tag, false);
	};

	return (
		<div
			{...{
				className: "SelectTag",
				style: {
					flexWrap: wrapped ? "wrap" : "nowrap",
					marginBottom: !!values && _.isArray(values) ? -3 : 0,
					...style,
				},
			}}
		>
			{values &&
				_.isArray(values) &&
				values.map((tag, key) => {
					return (
						<Tag
							{...{
								key,
								onDelete:
									!readOnly && tag
										? () => {
												if (!readOnly && tag) {
													handleDeleteTag(tag);
												}
										  }
										: null,
								text: tag,
								style: { marginRight: 3, marginBottom: 3 },
							}}
						/>
					);
				})}
			{!readOnly && !isCreate && (
				<div
					{...{
						className: "SelectTag_createButton",
						style: { ...(!values && { display: "flex", opacity: "0.3" }) },
						onClick: () => setIsCreate(true),
					}}
				>
					<ConfigIcon {...{ color: "var(--text-tenth)", size: 18 }}>
						<HiPlusSm />
					</ConfigIcon>
					<span>Добавить тег</span>
				</div>
			)}
			{!readOnly && isCreate && (
				<div {...{ className: "SelectTag_createContainer" }}>
					<EditableDiv
						{...{
							placeholder: "текст",
							value: tag,
							fontSize: 16,
							divRef: ref,
							onChange: setTag,
							onBlur: () => handleCreate(ref.current.innerText),
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default SelectTag;
