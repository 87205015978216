import { appTypes } from "../types/appTypes";
import _ from "lodash";

const initialState = {
	notifications: null,
	campaign_users: null,
	schemas: null,
	schemas_object_by_id: null,
	audioPlayer: null
};

export const appReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case appTypes.SET_NOTIFICATION: {
			const notification = {
				...payload,
				id: _.uniqueId()
			}
			return { ...state, notifications: _.isArray(state.notifications) ? [...state.notifications, notification] : [notification]};
		}
		case appTypes.DELETE_NOTIFICATION: {
			const notifications = [...state.notifications]
			return { ...state, notifications: notifications.filter(item => item.id !== payload)};
		}
		case appTypes.SET_CAMPAIGN_USERS: {
			const roles = payload ? [...payload] : null;
			const users = [];
			if (roles) {
				roles.forEach((role) => (role.user ? users.push(role.user) : true));
			}
			return { ...state, campaign_users: roles ? users : null };
		}
		case appTypes.SET_SCHEMAS_PULL: {
			return {
				...state,
				schemas: payload.schemas,
				schemas_object_by_id: payload.schemas_object
			}
		}
		case appTypes.SET_AUDIO_PLAYER: {
			return {
				...state,
				audioPlayer: payload
			}
		}
		default:
			return state;
	}
};
