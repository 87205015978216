import React from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createObject, getSingleView, getSingleObject, updateObject } from "../../../api/routes/objects_core";
import { BAD, SUCCESS } from "../../../helpers/response-service";
import { singleObject_Init, singleObject_resetSingleObject, singleObject_setLoading } from "../../../store/actions/singleObjectActions";
import SingleObjectColumn from "../../../components/elements/SingleObject/SingleObjectColumn";
import {formatFieldValues, isValue} from "../../../helpers/values-validator";
import Page404 from "../../error/Page404";
import ObjectPageSkeleton from "../../../components/kit/Loaders/ObjectPageSkeleton";
import _ from "lodash";

const SingleObject = (props) => {
  const { schema_id, root_object_path } = props;

  const __singleObject = useSelector((state) => state.singleObject.__singleObject);
  const singleObjectPositionedLayout = useSelector((state) => state.singleObject.singleObjectPositionedLayout);
  const loading = useSelector((state) => state.singleObject.loading);
  const [isCreateMode, setIsCreateMode] = React.useState(false);

  const { object_id } = useParams();
  const createStoreAction = useDispatch();

  const handleUpdateSingleObjectField = (field, values) => {
    return new Promise((resolve, reject) => {
      const payload = {
        query: object_id,
        data: [
          {
            field_id: field.id,
            values: formatFieldValues(field.type, values),
          },
        ],
      };
      updateObject(payload)
        .then((result) => {
          if (result.kind === SUCCESS) {
            resolve(result.data);
          } else {
            reject(result.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  // Init requested data
  React.useEffect(() => {
    createStoreAction(singleObject_setLoading(true));

    const EXIST_OBJECT_CONDITION = object_id && root_object_path;
    if (EXIST_OBJECT_CONDITION) {
      setIsCreateMode(false);
      const payload = {
        query: `id=${object_id}`,
        data: null,
      };
      getSingleObject(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              createStoreAction(singleObject_Init(result.data));
          }
        })
        .catch((e) => {
          // some error actions
        })
        .finally(() => {
          createStoreAction(singleObject_setLoading(false));
          // some finally actions
        });
    } else {
      setIsCreateMode(true);
      const payload = {
        query: schema_id,
        data: null,
      };
      getSingleView(payload)
        .then((result) => {
          if (result.kind === SUCCESS) {
            createStoreAction(singleObject_Init(result.data));
          }
        })
        .catch((e) => {
          // some error actions
        })
        .finally(() => {
          createStoreAction(singleObject_setLoading(false));
          // some finally actions
        });
    }

    return () => {
      createStoreAction(singleObject_setLoading(true));
      createStoreAction(singleObject_resetSingleObject(null));
    };
  }, [object_id, schema_id, root_object_path]);

	const createObjectRequest = (fieldCollection, owners) =>
		new Promise((resolve, reject) => {
			const payload = {
				query: `schema_id=${schema_id}`,
				data: {
					data: fieldCollection,
					...(isValue(owners) && {owner_id: owners[0]})
				},
			};
			createObject(payload)
				.then((result) => {
					if (result.kind === SUCCESS) {
						if (result.data.id && root_object_path) {
							resolve({ resultPath: root_object_path + result.data.id });
						}
					} else {
						reject({
							error: {
								status: BAD,
								data: result.data,
							},
						});
					}
				})
				.catch((e) =>
					reject({
						error: {
							status: BAD,
							data: e,
						},
					})
				);
		});

  const isStoreObjectSameId = __singleObject && __singleObject.id === object_id * 1;

  const SingleObject_DOM = (loading, layout) => {
    if (loading) {
      return <ObjectPageSkeleton />;
    } else if (!loading && (isStoreObjectSameId || isCreateMode) && layout) {
      return Object.keys(layout).map((column_position_key) => (
        <SingleObjectColumn
          {...{
            objectSchemaId: __singleObject.schema_id,
            isCreateMode,
            createObjectRequest,
            handleUpdateSingleObjectField,
            key: _.uniqueId(`__SingleObjectColumnKey_${column_position_key}`),
            data: layout[column_position_key],
          }}
        />
      ));
    } else return <Page404 />;
  };

  return <div {...{ className: "SingleObject flex-row" }}>{SingleObject_DOM(loading, singleObjectPositionedLayout)}</div>;
};

export default SingleObject;
