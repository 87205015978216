import React from "react";
import "./index.css";
import { VIEW_WIDGETS_PULL } from "../../../../constants/widgets";
import _ from "lodash";

const TableHeader = (props) => {
  const { row, max } = props;

  return (
    <div {...{ className: "TableHeader" }}>
      <div
        {...{
          key: _.uniqueId("row"),
          className: "RowCardFieldsIteration_table",
        }}>
        {row &&
          row.map((field, index) => {
            if (Object.keys(VIEW_WIDGETS_PULL).includes(field.view_widget) && index < max) {
              return (
                <div
                  {...{
                    key: _.uniqueId("field") + field.id,
                    className: "RowCardFieldsIteration_tableCell",
                  }}>
                  {field.name}
                </div>
              );
            } else return null;
          })}
      </div>
    </div>
  );
};

export default TableHeader;
