import axios from "axios";
import { Api, RESPONSE_TYPE_BLOB } from "./api-service";

// const ENV_HOST = process.env.REACT_APP_API_URL_BASE;

let HOST = "https://dev.crm.trackup.org/api/dev";
let PROD_HOST = "https://crm.trackup.org/api";

console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
  HOST = PROD_HOST;
}

const client = axios.create({
  baseURL: HOST,
});

client.interceptors.request.use(
  (config) => {
    let access_token = localStorage.getItem("access_token");
    if (access_token !== null) {
      config.headers.Authorization = "Bearer " + access_token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const logOut = async () => {
  // console.log("unauthorize");
};

const api = new Api(client, logOut);
export { api, RESPONSE_TYPE_BLOB };
