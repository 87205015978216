import { CSSProperties, ReactNode, useRef } from "react";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import IconButton from "../../Buttons/IconButton";
import { TbDotsVertical } from "react-icons/tb";
import ConfigIcon from "../../Icons/ConfigIcon";
import _ from "lodash";
import "./index.css";

export interface IconButtonAction {
  icon?: ReactNode;
  name: string;
  action: (item: IconButtonAction) => void;
}

interface SelectIconActionProps {
  style?: CSSProperties;
  className?: string;
  headerSize?: number;
  icon?: ReactNode;
  color?: string;
  iconSize?: number;
  data?: IconButtonAction[];
  onClick?: () => void;
  disabled?: boolean;
}

const SelectIconAction = (props: SelectIconActionProps) => {
  const {
    style,
    className,
    headerSize,
    icon, // {jsx, size, color}
    color,
    iconSize,
    data,
    onClick,
    disabled,
  } = props;

  const ref = useRef<any>(null);

  const handleAction = (item: IconButtonAction) => {
    if (disabled) {
      return;
    }
    if (item && item.action && typeof item.action === "function") {
      item.action(item);
      ref.current.handleContainer();
    }
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        ref,
        disabled,
        className,
        header: (
          <IconButton
            {...{
              icon: icon ? icon : <TbDotsVertical />,
              width: headerSize ? headerSize : 30,
              height: headerSize ? headerSize : 30,
              size: iconSize ? iconSize : 20,
              style: {
                ...style,
                background: style && style.background ? style.background : "transparent",
              },
              color: color ? color : "var(--gray1)",
              onClick: onClick ? onClick : undefined,
            }}
          />
        ),
      }}>
      {data && _.isArray(data) ? (
        <div {...{ className: "SelectIconAction_variants flex-column" }}>
          {data.map((item, key) => {
            return (
              <div
                {...{
                  key,
                  onClick: () => handleAction(item),
                  className: `SelectIconAction_variantsItem flex-row align-center`,
                }}>
                <div
                  {...{
                    className: "SelectIconAction_variantsItemInner flex-row align-center",
                  }}>
                  {item.icon ? <ConfigIcon {...{ size: 16, style: { marginRight: 6 } }}>{item.icon}</ConfigIcon> : null}
                  <span {...{ className: "text-dotted-end" }}>{item.name ? item.name : "Действие"}</span>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </ClickOutsideBoundaryTemplate>
  );
};

export default SelectIconAction;
