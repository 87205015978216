import React from "react";
import "./index.css";
import { useAuth } from "../../../providers/AuthProvider";
import { logged } from "../../../router/logged";
import { useLocation } from "react-router-dom";
import LogoContainer from "./LogoContainer";
import UserContainer from "./UserContainer";
import BottomContainer from "./BottomContainer";
import MenuListElements from "./MenuListElements";

const SideMenu = () => {
  const LOGGED_PAGES_ARRAY = logged("")[0].children;

  const groups = () => {
    let data = [];
    let meta_names = {};
    LOGGED_PAGES_ARRAY.forEach((page) => {
      if (!page.menu) {
        return null;
      }
      if (page.meta_name) {
        if (!meta_names[page.meta_name]) {
          meta_names[page.meta_name] = {
            icon: icons[page.meta_name],
            name: "apple",
            menu: true,
            meta_name: page.meta_name,
            group: [page],
            id: page.id,
            condition_path: page.condition_path,
          };
        } else {
          meta_names[page.meta_name].group.push(page);
        }
      } else {
        data.push(page);
      }
    });
    return [...Object.values(meta_names).map((group) => (group.group.length === 1 ? group.group[0] : group)), ...data];
  };

  const { logoutAction } = useAuth();

  const location = useLocation();

  const getLocation = (data) => {
    if (!data) {
      return;
    }
    let index = data.indexOf("/");
    let str = index === 0 ? data.replace("/", "") : data.slice(0, index);
    index = str.indexOf("/");
    str = index === -1 ? str : str.substring(0, str.indexOf("/"));
    return str;
  };

  return (
    <div
      {...{
        className: `SideMenu flex-column align-center justify-center`,
      }}>
      <LogoContainer />
      <MenuListElements {...{ elements: groups(), location, getLocation }} />
      <BottomContainer {...{ logoutAction }} />
      <UserContainer />
    </div>
  );
};

export default SideMenu;

const icons = {
  __lead__: "home-dollar",
  __client__: "address-book",
  __task__: "clipboard-check",
};
