import { ClickOutsideBoundaryTemplate } from "../../templates/ClickOutsideBoundaryTemplate";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MenuElement from "../SideMenu/MenuElement";
import cn from "classnames";
import styles from "./index.module.css";
import { phoneFormatter, removeLetters } from "../../../helpers/phone-formatter";
import IconManager from "../../kit/Icons/IconManager";
import { useDispatch } from "react-redux";
import { setCallExtension } from "../../../store/reducers/extensionsReducer";

const TelephonyCustomNumber = () => {
  const ref = useRef<any>();
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const header = () => {
    return (
      <MenuElement
        icon={"phone"}
        path={null}
        active={false}
        children={undefined}
        meta_name={undefined}
        getLocation={undefined}
        onClick={undefined}
        condition_path={undefined}
      />
    );
  };

  const onChangePhoneNumber = (value: string) => {
    setPhone(phoneFormatter(value));
  };

  const handlePhoneButton = useCallback((num: number | string | null) => {
    setError(false);
    if (num === null) {
      setPhone((prev) => phoneFormatter(prev.slice(0, prev.length - 1)));
    }
    setPhone((prev) => phoneFormatter(prev + num));
  }, []);

  const Buttons = useMemo(() => {
    return (
      <div className={cn(styles.numbersWrapper)}>
        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "0", null].map((num) => {
          return (
            <PhoneButton
              key={num}
              number={num}
              onClick={() => handlePhoneButton(num)}
            />
          );
        })}
      </div>
    );
  }, [handlePhoneButton]);

  const createCall = () => {
    if (removeLetters(phone).length !== 11) {
      setError(true);
      return;
    }

    const payload = {
      object_id: null,
      phone: {
        value: removeLetters(phone),
      },
    };
    dispatch(setCallExtension(payload));
    ref?.current?.handleContainer();
  };

  useEffect(() => {
    return () => {
      setError(false);
      setPhone("");
    };
  }, []);

  return (
    <ClickOutsideBoundaryTemplate
      header={header}
      ref={ref}>
      <div className={cn(styles.wrapper)}>
        <div
          onClick={() => ref?.current?.handleContainer()}
          className={cn(styles.closeBtn)}>
          <IconManager
            icon={"x"}
            size={14}
            color={"var(--text-fifth)"}
          />
        </div>
        <input
          onChange={(e) => onChangePhoneNumber(e.target.value)}
          value={phone}
          placeholder={"Номер телефона"}
          className={cn(styles.phoneInput, error && styles.phoneInputError)}
        />
        {Buttons}
        <div
          onClick={createCall}
          className={cn(styles.createCallButton)}>
          <IconManager
            icon={"phone"}
            size={20}
            color={"#fff"}
          />
        </div>
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

const PhoneButton = ({ number, onClick }: { number: number | string | null; onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.phoneButton)}>
      {number === null ? (
        <IconManager
          icon={"backspace"}
          size={20}
          color={"var(--text-tenth)"}
        />
      ) : number === "+" ? (
        <IconManager
          icon={"plus"}
          size={18}
          color={"var(--text-tenth)"}
        />
      ) : (
        number
      )}
    </div>
  );
};

export default TelephonyCustomNumber;
