import React from "react";
import MenuElement from "../MenuElement";

const MenuListElements = (props) => {
  const { elements, location, getLocation } = props;

  return (
    <>
      {elements.map((element) => {
        return (
          element.menu && (
            <MenuElement
              {...{
                children: element.group,
                key: element.icon,
                getLocation,
                icon: element.icon,
                meta_name: element.meta_name,
                path: element.path,
                condition_path: element.condition_path,
              }}
            />
          )
        );
      })}
    </>
  );
};

export default MenuListElements;
