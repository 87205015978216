import React from "react";
import "./index.css";
import { TbPlus } from "react-icons/tb";
import SelectIconAction from "../../kit/Selects/SelectIconAction";
import FastObjectCreate from "../FastObjectCreate";
import { COLORS } from "../../../constants/types";
import { useDispatch, useSelector } from "react-redux";
import { multiplyObjects_addColumnNewObject } from "../../../store/reducers/multiplyObjectsReducer";
import IconManager from "../../kit/Icons/IconManager";
import { formatPrice } from "../../../helpers/price-formatter";

const ColumnList = (props) => {
  const { schemaFields, children, grouped_by, schema_id } = props;

  const containerRef = React.useRef(null);
  const createStoreAction = useDispatch();
  const count = useSelector((state) => state.multiplyObjects.__groupedObjects_counts?.[grouped_by.choice_id]?.count) || 0;

  const selectActionData = [
    {
      name: "Создать",
      icon: <TbPlus />,
      action: () => containerRef.current.handleContainer(),
    },
  ];

  const onCreateNewObject = (object) => {
    if (object && object.id) {
      const payload = {
        choice_id: grouped_by.choice_id,
        data: object,
      };
      createStoreAction(multiplyObjects_addColumnNewObject(payload));
    }
  };

  const replaceString = (data, limitWord = 21) => {
    if (data?.length && data?.length > limitWord) {
      return `${data?.slice(0, limitWord)}...`;
    }
    return data;
  };

  return (
    <div
      {...{
        className: "ColumnList flex-column",
      }}>
      <div {...{ className: "ColumnList_title flex-column justify-center" }}>
        <div {...{ className: "ColumnList_titleName flex-row align-items" }}>
          <span {...{ className: "ColumnList_titleNameText" }}>
            {grouped_by && grouped_by.value && grouped_by.value?.split(" ").join("") ? replaceString(grouped_by.value) : "Untitled"}
          </span>
          <div {...{ className: "ColumnList_titleCount" }}>{count === 0 ? 0 : formatPrice(count)}</div>
          {grouped_by.need_approve ? <IconManager {...{ icon: "circle-dot", color: "var(--text-error)", size: 12 }} /> : null}
        </div>
        <div
          {...{
            className: "ColumnList_titleColor",
            style: { background: COLORS[grouped_by.color] },
          }}></div>
        {schemaFields && schema_id && (
          <div {...{ className: "ColumnList_controls align-center justify-end" }}>
            <FastObjectCreate
              {...{
                schemaFields,
                schema_id,
                onCreate: onCreateNewObject,
                containerRef,
                includeFields: [
                  {
                    field_id: grouped_by.field_id,
                    values: [grouped_by.value],
                  },
                ],
              }}
            />
            <SelectIconAction {...{ data: selectActionData }} />
          </div>
        )}
      </div>
      <div {...{ className: "ColumnListCards flex-column" }}>{children}</div>
    </div>
  );
};

export default ColumnList;
