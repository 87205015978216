import { IconChevronDown } from "@tabler/icons-react";
import cn from "classnames";
import React, { ReactNode, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./AccordionBase.css";
import { useToggle } from "usehooks-ts";
import { MetaNameToName } from "../../../../constants/MetaNamesEnum";

interface AccordionProps {
  children: ReactNode;
  label: string;
  meta_name?: string;
}

const AccordionBase = ({ children, label, meta_name }: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasMargin, toggleHasMargin] = useToggle(false);

  return (
    <>
      <motion.div
        {...{
          // transition: { type: "", duration: 0.1 },
          // animate: { marginBottom: isExpanded ? 18 : 0 },
          className: cn("Accordion_trigger flex-row justify-between align-center w-100"),
          onClick: () => setIsExpanded(!isExpanded),
        }}>
        <div>
          <div className="Accordion_metaName">{meta_name !== undefined && MetaNameToName[meta_name as keyof typeof MetaNameToName]}</div>
          <div>{label}</div>
        </div>
        <motion.span
          {...{
            onAnimationStart: () => toggleHasMargin(),
            onAnimationComplete: () => toggleHasMargin(),
            transition: { duration: 0.3, bounce: 0.3, damping: 13 },
            animate: { rotate: isExpanded ? 180 : 0 },
          }}>
          <IconChevronDown />
        </motion.span>
      </motion.div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            {...{
              transition: {
                duration: 0.3,
                bounce: false,
              },
              initial: {
                opacity: 0,
                height: 0,
                overflow: "hidden",
              },
              animate: {
                opacity: 1,
                height: "auto",
                overflow: "visible",
              },
              exit: {
                opacity: 0,
                height: 0,
                overflow: "hidden",
              },
              // ...SHOW_ANIMATION_MOTIONS.default_height(isExpanded, "closed"),
              className: cn(
                "Accordion_container"
                // (hasMargin || isExpanded) && "margin-top-14"
              ),
            }}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AccordionBase;
