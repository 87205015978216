import React from "react";
import styles from "./index.module.css";
import UngroupedViewRowList from "../../../components/elements/UngroupedView/UngroupedViewRowList";
import CardRow from "../../../components/elements/Rows/CardRow";
import { getUnGroupedObjects } from "../../../api/routes/objects_core";
import { SUCCESS } from "../../../helpers/response-service";
import cn from "classnames";
import UngroupedViewHeader from "../../../components/elements/UngroupedView/UngroupedViewHeader";
import { OBJECT_VIEW_TYPES, VIEW_TYPES } from "../../../constants/types";
import SkeletonView from "../../../components/kit/Loaders/SkeletonView";
import { isValue } from "../../../helpers/values-validator";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const UngroupedView = (props) => {
  const { schema } = props;

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [paginationLoading, setPaginationLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const navigate = useNavigate();

  const getData = (isPagination, filter, page) => {
    const payload = {
      query: {
        schema_id: schema.id,
        view_type: OBJECT_VIEW_TYPES.ROW,
        page: page,
        limit: 30,
      },
      data: filter ? filter : {},
    };
    if (payload.query.page === -1) {
      return null;
    }
    payload.query = new URLSearchParams(payload.query).toString();
    isPagination ? setPaginationLoading(true) : setDataLoading(true);
    getUnGroupedObjects(payload)
      .then((result) => {
        switch (result.kind) {
          case SUCCESS:
            const objects = result.data;
            setObjectsData(_.isArray(objects) ? objects : [], isPagination);
        }
      })
      .finally(() => {
        setDataLoading(false);
        setPaginationLoading(false);
      });
  };

  const setObjectsData = (objects, isPagination) => {
    // console.log("objects", objects);
    if (isPagination) {
      isValue(objects) ? setData((prev) => [...prev, ...objects]) : setPage(-1);
    } else {
      setData(objects);
    }
  };

  const onListEnd = () => {
    if (page >= 0) {
      setPage((prev) => prev + 1);
    }
  };

  // TODO
  const onElementClick = (item) => {
    navigate(`/${schema.key}/${item.id}`);
    // console.log(item.id)
  };

  // for first loading
  React.useEffect(() => {
    getData(false, false, 0);

    return () => {
      resetPage();
    };
  }, [schema, schema.id]);

  const resetPage = () => {
    setPage(0);
    setData(0);
    setFilters({});
  };

  // for pagination
  React.useEffect(() => {
    if (page > 0) {
      getData(true, filters, page);
    }
  }, [page]);

  const getFilteredObjects = (filter) => {
    setFilters(filter);
    setPage(0);
    getData(false, filter, 0);
  };

  return (
    <div
      {...{
        className: cn(styles.ungroupedView),
      }}>
      <UngroupedViewHeader
        {...{
          schema,
          getObjects: getFilteredObjects,
        }}
      />
      <div
        {...{
          className: cn(styles.ungroupedViewContent, DefaultScrollBar),
        }}>
        {dataLoading ? (
          <SkeletonView {...{ view: VIEW_TYPES.TABLE }} />
        ) : (
          <UngroupedViewRowList
            {...{
              style: { paddingLeft: 14, paddingRight: 14, paddingBottom: 14 },
              isPaginationLoading: paginationLoading,
              data,
              onElementClick,
              onListEnd,
              element: (data) => <CardRow {...{ data }} />,
              elementWrapperStyle: RowCardStyles,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UngroupedView;

const RowCardStyles = {
  marginBottom: 2,
  width: "100%",
  background: "var(--card-background)",
  borderRadius: 6,
  minHeight: 45,
  display: "flex",
  alignItems: "center",
};

const DefaultScrollBar = "default-scrollbar";
